// import parsePhoneNumber from 'libphonenumber-js/max';
import axios from './axios';
import { appConfig } from '../config';
import Cookies from 'js-cookie';

const logoutUserHandler = async () => {
  if (!Cookies.get('access_token')) {
    window.location.href = '/';
  }
  await axios.get(`${appConfig.API_URL}/api/logout`);
  window.location.href = '/signin';
};

const setUserInLocalStorage = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  }
};

const handleDomainRedirect = (access_token = null) => {
  const redirectUrl = localStorage.getItem('redirectUrl');
  if (!redirectUrl) return;
  localStorage.clear();

  if (access_token) {
    try {
      const urlWithToken = new URL(redirectUrl);
      const newQueryParams = new URLSearchParams(urlWithToken.search);
      newQueryParams.set('access_token', access_token);

      urlWithToken.search = newQueryParams.toString();
      window.location.href = urlWithToken.href;
    } catch (e) {
      console.log(e);
    }
  } else {
    window.location.href = redirectUrl;
  }
};

// const checkMobilePhoneValidity = (mobilePhone) => {
//   const phoneNumber = parsePhoneNumber(mobilePhone);
//   if (phoneNumber?.getType() === 'MOBILE' || phoneNumber?.getType() === 'FIXED_LINE_OR_MOBILE') {
//     return true;
//   } else {
//     return false;
//   }
// };

const errorMessageHandler = (responseData) => {
  let errorArray = [];
  if (responseData?.errors?.length) {
    responseData.errors.map((e) => errorArray.push(e.message));
  } else if (responseData?.message) {
    errorArray.push(responseData.message);
  } else {
    errorArray.push('Something went wrong!');
  }
  return errorArray;
};

export { logoutUserHandler, setUserInLocalStorage, errorMessageHandler, handleDomainRedirect };
