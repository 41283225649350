import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeRerender: false
};

export const HelperSlice = createSlice({
  name: 'HelperSlice',
  initialState,
  reducers: {
    setActiveRerender: (state) => {
      state.activeRerender = !state.activeRerender;
    }
  }
});

export const { setActiveRerender } = HelperSlice.actions;

export default HelperSlice.reducer;
