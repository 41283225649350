import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Outlet } from 'react-router-dom';
import { logoutUserHandler } from '../utils/helpers';

const Layout = () => {
  const logoutBtnHandler = () => {
    localStorage.clear();
    logoutUserHandler();
  };
  return (
    <div style={{ background: '#1A2038', height: '100vh' }}>
      <Grid container>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} m={3}>
            <img src="/assets/images/ob-logo.png" width="200px" height="auto" alt="logo" />
          </Grid>

          <Grid item m={3}>
            <Button size="large" sx={{ textTransform: 'none' }} variant="contained" onClick={logoutBtnHandler}>
              Logout
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Outlet />
        </Grid>
      </Grid>
    </div>
  );
};

export default Layout;
