import { combineReducers, configureStore } from '@reduxjs/toolkit';
import User from './slices/users';
import AlertsSlice from './slices/AlertsSlice';
import HelperSlice from './slices/HelperSlice';

const store = configureStore({
  reducer: combineReducers({ User, AlertsSlice, HelperSlice })
});

export const { dispatch, getState } = store;

export default store;
