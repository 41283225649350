import { lazy, useEffect, useRef, useState } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import Loadable from './components/Loadable';
import Layout from './components/layout';
import OTPVerification from './components/sessions/OTPVerification';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from './utils/axios';
import Loading from './components/MatxLoading';
import { handleDomainRedirect } from './utils/helpers';

const JwtLogin = Loadable(lazy(() => import('./components/sessions/JwtLogin')));
const ImpersonateLogin = Loadable(lazy(() => import('./components/sessions/ImpersonateLogin')));
// const JwtRegister = Loadable(lazy(() => import('./components/sessions/JwtRegister')));
const ForgotPassword = Loadable(lazy(() => import('./components/sessions/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('./components/sessions/ResetPassword')));
const WelcomPage = Loadable(lazy(() => import('./components/sessions/WelcomPage')));

const REG_EX_FOR_REDIRECT_URL = /redirect_url=(.*)/;

const Router = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const locationRef = useRef(location);
  const navigateRef = useRef(navigate);

  const [showLoading, setShowLoading] = useState(true);

  const matchedUrl = window.location.href.match(REG_EX_FOR_REDIRECT_URL);
  const redirectUrl = (matchedUrl ? matchedUrl[1] : null) || localStorage.getItem('redirectUrl');

  useEffect(() => {
    (async () => {
      try {
        const navigate = navigateRef.current;
        const path = locationRef.current.pathname;
        const isAuthRoute = [
          '/signin',
          // '/signup',
          '/forgot-password',
          '/reset-password',
          '/otp-verification',
          '/impersonate_login'
        ].includes(path);

        if (Cookies.get('access_token')) {
          const responseData = await axios.get('/api/user_details');
          if (responseData?.status === true) {
            if (redirectUrl) {
              handleDomainRedirect(Cookies.get('access_token'));
              return;
            }

            if (isAuthRoute) {
              navigate('/welcome');
            }
          }
        } else if (!isAuthRoute) {
          navigate('/signin');
        }
      } catch (e) {
        console.log(e);
      } finally {
        setShowLoading(false);
      }
    })();
  }, [redirectUrl]);

  useEffect(() => {
    if (redirectUrl) localStorage.setItem('redirectUrl', redirectUrl);
  }, [redirectUrl]);

  const routes = useRoutes([
    {
      path: '/',
      element: <Layout />,
      children: [
        { element: <Navigate to="/welcome" />, index: true },
        { path: '/welcome', element: <WelcomPage /> }
      ]
    },
    // { path: '/signup', element: <JwtRegister /> },
    { path: '/forgot-password', element: <ForgotPassword /> },
    { path: '/reset-password/:user_id/:token', element: <ResetPassword /> },
    { path: '/otp-verification', element: <OTPVerification /> },
    { path: '/impersonate_login', element: <ImpersonateLogin /> },
    { path: '/signin', element: <JwtLogin /> },

    { path: '*', element: <Navigate to="/" /> }
  ]);
  return showLoading ? <Loading /> : routes;
};

export default Router;
