import { memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelIcon from '@mui/icons-material/Cancel';

const NotificationAlerts = () => {
  const snackBarAlert = useSelector((state) => state.AlertsSlice.snackBarAlert);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const actionRenderer = useCallback(
    (key, severity) => {
      if (severity === 'success') {
        return <CheckCircleRoundedIcon onClick={() => closeSnackbar(key)} sx={{ cursor: 'pointer' }} />;
      } else {
        return <CancelIcon onClick={() => closeSnackbar(key)} sx={{ cursor: 'pointer' }} />;
      }
    },
    [closeSnackbar]
  );

  useEffect(() => {
    if (snackBarAlert) {
      Array.isArray(snackBarAlert.message)
        ? snackBarAlert.message.map((msg) =>
            enqueueSnackbar(msg, {
              variant: snackBarAlert.severity,
              action: (key) => actionRenderer(key, snackBarAlert.severity)
            })
          )
        : enqueueSnackbar(snackBarAlert.message, {
            variant: snackBarAlert.severity,
            action: (key) => actionRenderer(key, snackBarAlert.severity)
          });
    }
  }, [snackBarAlert, actionRenderer, enqueueSnackbar]);

  return null;
};

export default memo(NotificationAlerts);
