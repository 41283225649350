import NotificationAlerts from './components/alerts/NotificationAlerts';
import Router from './routes';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './reducers/configureStore';
import SnackNotificationProvider from './components/SnackNotificationProvider/SnackNotificationProvider';

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Provider store={store}>
          <SnackNotificationProvider>
            <NotificationAlerts />
            <Router />
          </SnackNotificationProvider>
        </Provider>
      </BrowserRouter>
    </>
  );
};

export default App;
